/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    if (typeof window.svg4everybody === 'function') {
        window.svg4everybody();
    }

    $(document).foundation();
});
