/*global window, SlickSliderOptions, document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    if (window.SlickSliderOptions !== undefined) {
        jQuery.each(SlickSliderOptions, function (key, configuration) {
            if (
                configuration !== null &&
                configuration.default !== undefined &&
                configuration.navigation !== undefined
            ) {
                // Note that only tabbedSliders are selected, so the behaviour of the code in this file won't affect
                // other sliders!
                // Please also note that the $tabbedSlider is NOT a slick slider, it's just a container around the
                // main slick slider
                var $tabbedSlider = $('.tabbedSlider > .slick_slider_' + key).parent(),
                    $mainSlider = $tabbedSlider.children('.slick_slider_' + key),
                    $navSlider = $('.slick_slider_nav_' + key),
                    sliderInterval,
                    intervalDelay = 100,
                    // Note that the intervalFunction won't be called as soon as the slider starts, it'll be called
                    // for the first time AFTER the first intervalDelay (since setInterval doesn't call your
                    // function directly, it gets called after [delay] milliseconds). So don't start the timePassed on 0,
                    // start it at [intervalDelay] :)
                    timePassed = intervalDelay,
                    intervalFunction = function () {
                        // Every time this function is called, add the delay amount to the timePassed variable
                        // When the timePassed variable is larger than the sliders autoplaySpeed, show the next slide
                        // and reset the timePassed variable to 0
                        timePassed += intervalDelay;

                        if (timePassed > configuration.default.autoplaySpeed) {
                            $mainSlider.slickNext();
                            timePassed = 0;
                        }
                    };

                if ($tabbedSlider.length === 0 || configuration.default.autoplay === false) {
                    // No tabbedSliders|autoplay tabsliders on this page, so don't execute all this code below
                    return;
                }

                // Upgrading from slick-slider 1.3.15 to 1.5.x is a major change that can break our existing platforms. Instead of
                // using their changed API, remove the mouseenter+mouseleave event from the slick-track. In v1.3.15 it's
                // not possible to set pauseOnHover to false on runtime, with this workaround we can do that.
                $mainSlider.find('.slick-list').off('mouseenter.slick mouseleave.slick');
                $mainSlider.slickSetOption('autoplay', false);
                window.clearInterval($mainSlider.getSlick().autoPlayTimer);

                // Start the progressbar!
                sliderInterval = window.setInterval(intervalFunction, intervalDelay);

                // We don't know the (slider specific) autoplaySpeed in CSS, so set the speed of the progressbar
                // inline with JS
                $tabbedSlider.find('.tabbedSlider-timer').css({
                    '-webkit-animation-duration': configuration.default.autoplaySpeed + 'ms',
                    'animation-duration': configuration.default.autoplaySpeed + 'ms',
                });

                // When hovering the tabbedSlider, stop the interval and add a paused class. This class stops the
                // animation. Don't reset the timePassed var, so when you stop hovering, the timer will continue on the
                // same position as where it was paused
                $tabbedSlider.on('mouseenter', function () {
                    window.clearInterval(sliderInterval);
                    $tabbedSlider.addClass('tabbedSlider--paused');
                });

                // Set a new interval and remove the paused class, so the animation will resume playing
                $tabbedSlider.on('mouseleave', function () {
                    sliderInterval = window.setInterval(intervalFunction, intervalDelay);
                    $tabbedSlider.removeClass('tabbedSlider--paused');
                });

                // Set a 'slickSlider-animateProgressBar' class on the first slide, which enables the progress bar
                $navSlider.find('.slick-slide').not('.slick-cloned').first().addClass('slickSlider-animateProgressBar');

                /*jslint unparam: true*/
                $mainSlider.on('afterChange', function (event, slickElement, index) {
                    // We changed slide, so reset the counter to 0
                    timePassed = 0;

                    // Search for the previous active slide, which still has the animateProgressBar class. Store it in
                    // a variable, we'll remove the class a couple of milliseconds later (see explanation below)
                    var $currentlyActive = $navSlider.find('.slick-slide.slickSlider-animateProgressBar');
                    // Show a progress bar on the new active slide
                    $navSlider.find("[index='" + index + "']").addClass('slickSlider-animateProgressBar');

                    // Remove the class when the progress bar is hidden. It'll be hidden with a transition, so remove the
                    // progressbar animation AFTER the hide-transition is finished. The duration of this transition is
                    // 500ms, which can be changed in _tabbedSlider.scss (.tabbedSlider-timer)
                    window.setTimeout(function () {
                        $currentlyActive.removeClass('slickSlider-animateProgressBar');
                    }, 500);
                });
                /*jslint unparam: false*/
            }
        });
    }
});
