/*global document,window,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var $window = $(window);
    var $body = $(document.body);

    //  ========================
    //  Wrap personal teaser title
    //  ========================

    (function ($) {
        $.fn.inlinebackgrounds = function () {
            $.each(this, function (i, t) {
                var split = $(t).html().split('<br>');
                var output = '';
                $.each(split, function (i, o) {
                    output += '<span>' + o + '</span>';
                });
                $(t).html(output);
            });
        };
    })(jQuery);

    (function ($) {
        $.fn.breakwords = function () {
            $.each(this, function (i, t) {
                var current = $(t);
                var text = current.text();
                var words = text.split(' ');

                current.text(words[0]);
                var height = current.height();

                var breakWords = [];

                for (var j = 1; j < words.length; j++) {
                    current.text(current.text() + ' ' + words[j]);

                    if (current.height() > height) {
                        height = current.height();
                        breakWords.push(words[j]);
                    }
                }

                if (breakWords) {
                    for (var b = 0; b < breakWords.length; b++) {
                        text = text.replace(breakWords[b], '<br />' + breakWords[b]);
                        current.html(text);
                    }
                }
            });
        };
    })(jQuery);

    $window.on('load resize', function () {
        var $maintitle = $('.block-teaser-personal .maintitle');
        var $subtitle = $('.block-teaser-personal .teaser-subtitle');

        $maintitle.breakwords();
        $maintitle.inlinebackgrounds();

        $subtitle.breakwords();
        $subtitle.inlinebackgrounds();

        var $greenTitle = $('.green-title');
        var $whiteTitle = $('.white-title');

        $greenTitle.breakwords();
        $greenTitle.inlinebackgrounds();

        $whiteTitle.breakwords();
        $whiteTitle.inlinebackgrounds();
    });

    //  ========================
    //  Video
    //  ========================

    var $popupVideo = $('.popup-video');

    if ($popupVideo.length > 0) {
        $popupVideo.magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
        });
    }

    //  ========================
    //  Video
    //  ========================

    var $gallery = $('.js-block-gallery');

    if ($gallery.length > 0) {
        $gallery.each(function () {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    tCounter: '',
                    navigateByImgClick: true,
                    preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                },
                callbacks: {
                    buildControls: function () {
                        // When there are controls, re-append the controls inside the main container
                        if (this.items.length > 1) {
                            this.contentContainer.find('.mfp-figure').append(this.arrowLeft.add(this.arrowRight));
                        }
                    },
                },
            });
        });
    }

    //  ========================
    //  Animations
    //  ========================

    $('.block').onScreen({
        doIn: function () {
            $(this).addClass('onscreen');
        },
        tolerance: 200,
        throttle: 50,
        toggleClass: '',
        lazyAttr: null,
        debug: false,
    });

    //  ========================
    //  Forms
    //  ========================

    //  Custom radio/checkbox
    var toggle_label = function () {
        $labels.removeClass('selected');
        $labels.filter(':has(:checked)').addClass('selected');
    };
    var $labels = $('.checkbox, .radio').closest('label').on('click', toggle_label);
    toggle_label();

    //  Parsley events
    var $form = $('.block-text form');
    var changeClasses = function ($field) {
        var $parent = $field.parent();

        //  Check if field is mandatory, if so: add custom class to it's parent
        if ($field.prop('required')) {
            $parent.addClass('c-required');

            setTimeout(function () {
                //  Check if Parsley returned error on this field, add custom error class to it's parent
                if (false !== $field.hasClass('parsley-error')) {
                    $parent.removeClass('c-success').addClass('c-error');
                }

                //  Check if Parsley returned success on this field, add custom success class to it's parent
                if (false !== $field.hasClass('parsley-success')) {
                    $parent.removeClass('c-error').addClass('c-success');
                }
            }, 10);
        }
    };

    //  Find all input fields in a form to determine wich fields are mandatory
    $form.find('input, textarea').each(function () {
        changeClasses($(this));

        $(this).on('change', function () {
            changeClasses($(this));
        });
    });

    $form.on('submit', function () {
        $(this)
            .find('input, textarea')
            .each(function () {
                changeClasses($(this));
            });
    });

    //  ========================
    //  Print
    //  ========================

    $('.js-printButton').on('click', function (ev) {
        ev.preventDefault();

        window.print();
    });
});
