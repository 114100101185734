/*global window,document,jQuery,Math,Date,setTimeout*/
jQuery(document).ready(function ($) {
    'use strict';

    if ($('.TimerCountdown')[0]) {
        var targetDate = $('.TimerCountdown').attr('data-count-down'),
            startDate = new Date(targetDate * 1000),
            today = new Date();

        function calcultateTimeNewDate() {
            var today = new Date(),
                oneSecond = 1000,
                oneMinute = 60 * 1000,
                // minutes*seconds*milliseconds
                oneHour = 60 * 60 * 1000,
                oneDay = oneHour * 24,
                // oneDay * 365.25 (because leap year)
                oneYear = oneDay * 365.25,
                //calculate milliseconds between the two dates and put result in msBetween
                msBetween = Math.round(Math.abs(startDate.getTime() - today.getTime())),
                yearsBetween = Math.floor(msBetween / oneYear),
                daysBetween = Math.floor((msBetween - yearsBetween * oneYear) / oneDay),
                hoursBetween = Math.floor((msBetween - yearsBetween * oneYear - daysBetween * oneDay) / oneHour),
                minutesBetween = Math.floor(
                    (msBetween - yearsBetween * oneYear - daysBetween * oneDay - hoursBetween * oneHour) / oneMinute,
                ),
                secondsBetween = Math.floor(
                    (msBetween -
                        yearsBetween * oneYear -
                        daysBetween * oneDay -
                        hoursBetween * oneHour -
                        minutesBetween * oneMinute) /
                        oneSecond,
                );

            $('.js-timeCounterItem-days').text(daysBetween.toString().length < 2 ? '0' + daysBetween : daysBetween);
            $('.js-timeCounterItem-hours').text(hoursBetween.toString().length < 2 ? '0' + hoursBetween : hoursBetween);
            $('.js-timeCounterItem-minutes').text(
                minutesBetween.toString().length < 2 ? '0' + minutesBetween : minutesBetween,
            );
            $('.js-timeCounterItem-seconds').text(
                secondsBetween.toString().length < 2 ? '0' + secondsBetween : secondsBetween,
            );
        }

        if (startDate >= today) {
            setInterval(function () {
                calcultateTimeNewDate();
            }, 1000);
        }
    }
});
