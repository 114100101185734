/*global window,document,jQuery,Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    var toggleFooterMenu = $('.js-pageFooter-mainBar header');

    toggleFooterMenu.on('click', function () {
        if (Foundation.MediaQuery.atLeast('large') === false) {
            toggleFooterMenu.not($(this)).removeClass('is-active');
            $(this).toggleClass('is-active');
        }
    });
});
