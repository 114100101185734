/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var anchorButton = $('.js-hero-button');

    function scrollToSection(id) {
        $('html, body').animate(
            {
                scrollTop: id.offset().top - $('.PageHeader').outerHeight(),
            },
            1000,
        );
        return false;
    }

    if (anchorButton.length) {
        anchorButton.on('click', function (e) {
            e.preventDefault();

            var anchorLink = $(this).attr('data-hero');

            if (anchorLink.slice(0, 1) === '#' && $(anchorLink).length) {
                scrollToSection($(anchorLink));
            } else {
                scrollToSection($(this).parents('.js-hero').next());
            }
        });
    }
});
